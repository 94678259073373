<template>
	<v-menu
		ref="menu"
		v-model="menu"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		min-width="auto"
	>
		<template v-slot:activator="{ on, attrs }">
			<div>
				<div class="primary--text subtitle-2 ml-2">{{ title }}</div>
				<v-text-field
					class="rounded-lg"
					v-model="date"
					readonly
					dense
					outlined
					:rules="rules"
					:placeholder="showPlaceholder"
					@focus="$emit('focus')"
					@update:error="error = $event"
					v-bind="!menuDisabled ? attrs : null"
					v-on="!menuDisabled ? on : null"
				>
					<template v-slot:prepend-inner>
						<v-icon class="material-icons-outlined">event</v-icon>
					</template>
					<template v-slot:append>
						<v-icon
							:color="icon.color"
							class="material-icons-outlined"
							>{{ icon.icon }}</v-icon
						>
					</template>
				</v-text-field>
			</div>
		</template>
		<v-date-picker
			v-model="dateCalendar"
			no-title
			:allowed-dates="disabledDays"
			@input="menu = false"
			scrollable
		>
			<v-spacer></v-spacer>
			<v-btn
				class=" mb-n1"
				small
				text
				color="primary"
				@click="menu = false"
			>
				{{ $t("global.btnCancel") }}
			</v-btn>
			<v-btn
				class="mb-n1 "
				small
				text
				color="primary"
				@click="$refs.menu.save(dateCalendar)"
			>
				OK
			</v-btn>
		</v-date-picker>
	</v-menu>
</template>

<script>
import dayjs from "dayjs";
import {
	FORMAT_TIME,
	FORMAT_DATE,
	FORMAT_DATE_PICKER
} from "@/helpers/constants";
export default {
	props: {
		title: String,
		value: String,
		menuDisabled: Boolean,
		disabled: Boolean,
		allowedDates: Function,
		referenceDate: String,
		placeholder: String,
		disabledNext: Number,
		placeholderRules: {
			type: Boolean,
			default: true
		},
		disabledPrevius: Number,
		rules: {
			type: Array,
			default: function() {
				return [v => !!v || this.$t("global.required")];
			}
		}
	},
	data: () => ({
		menu: false,
		error: false
	}),

	computed: {
		time() {
			let aux = dayjs(this.value);
			return aux.isValid() ? aux.format(FORMAT_TIME) : "00:00:00";
		},
		dateCalendar: {
			get() {
				let aux = dayjs(this.value);
				return aux.isValid() ? aux.format(FORMAT_DATE_PICKER) : "";
			},
			set(v) {
				this.$emit("input", `${v} ${this.time}`);
			}
		},
		date: {
			get() {
				let aux = dayjs(this.value);
				return aux.isValid() ? aux.format(FORMAT_DATE) : "";
			},
			set(v) {
				this.$emit("input", `${v} ${this.time}`);
			}
		},
		showPlaceholder: vm => {
			if (vm.placeholderRules)
				if (vm.error) {
					return vm.rules.map(x => x())[0];
				}
			return vm.placeholder;
		},
		icon: vm =>
			!vm.error
				? vm.value
					? { icon: "check_circle", color: "green" }
					: { icon: "check_circle", color: "grey" }
				: { icon: "error_outline", color: "red" }
	},
	methods: {
		disabledDays(val) {
			if (this.disabledNext || this.disabledPrevius) {
				const date = dayjs(this.referenceDate).format(
					FORMAT_DATE_PICKER
				);
				const yerterday = dayjs(date).subtract(
					this.disabledPrevius,
					"day"
				);
				const nextDay = dayjs(date).add(this.disabledNext, "day");
				if (dayjs(val).isBefore(yerterday)) return false;
				if (dayjs(val).isAfter(nextDay)) return false;
				return true;
			}
			if (this.allowedDates) {
				return this.allowedDates(val);
			}
		}
	}
};
</script>
