<template>
	<div>
		<div class="picker arrows">
			<div
				:class="
					`swiper-container hours${_uid}  ${
						disabled ? 'swiper-no-swiping' : ''
					}`
				"
			>
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="hour in hours" :key="hour">
						{{ hour }}
					</div>
				</div>
			</div>
			<div
				:class="
					`swiper-container minutes${_uid} ${
						disabled ? 'swiper-no-swiping' : ''
					}`
				"
			>
				<div class="swiper-wrapper">
					<div
						class="swiper-slide"
						v-for="minute in minutes"
						:key="minute"
					>
						{{ minute }}
					</div>
				</div>
			</div>
			<div
				:class="
					`swiper-container seconds${_uid}  ${
						disabled ? 'swiper-no-swiping' : ''
					}`
				"
			>
				<div class="swiper-wrapper">
					<div
						class="swiper-slide"
						v-for="second in seconds"
						:key="second"
					>
						{{ second }}
					</div>
				</div>
			</div>
			<div class="vizor"></div>
		</div>
	</div>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import dayjs from "dayjs";
import { FORMAT_TIME, FORMAT_DATE_PICKER } from "@/helpers/constants";
export default {
	props: {
		value: String,
		disabled: Boolean
	},
	data: vm => ({
		hours: vm.generateNumber(24),
		minutes: vm.generateNumber(60),
		seconds: [" ", "hr", ""],
		actived: false,
		auxDate: null
	}),
	computed: {
		date() {
			let aux = dayjs(this.value);
			return aux.isValid() ? aux.format(FORMAT_DATE_PICKER) : "";
		},
		time() {
			let aux = dayjs(this.value);
			return aux.isValid() ? aux.format(FORMAT_TIME) : "00:00:00";
		},
		getHourPosition: vm => {
			const hourInt = Number(vm.time.split(":")[0]);
			const hourString = hourInt < 10 ? `0${hourInt}` : `${hourInt}`;
			const position = vm.hours.findIndex(x => x == hourString);
			return position;
		},
		getMinutePosition: vm => {
			const minuteInt = Number(vm.time.split(":")[1]);
			const minuteString =
				minuteInt < 10 ? `0${minuteInt}` : `${minuteInt}`;
			const position = vm.minutes.findIndex(x => x == minuteString);
			return position;
		}
	},
	methods: {
		generateNumber(cant) {
			return [...Array(cant).keys()].map(x =>
				x < 10 ? `0${x}` : `${x}`
			);
		},
		handlerTime({
			hour = undefined,
			minute = undefined,
			second = undefined
		}) {
			const [oldHour, oldMinute, oldSecond] = this.time.split(":");
			const newHour = hour ? hour : oldHour;
			const newMinute = minute ? minute : oldMinute;
			const newSecond = second ? second : oldSecond;
			const time = `${newHour}:${newMinute}:${newSecond}`;
			this.auxDate = `${this.date} ${time}`;
			this.$emit("input", this.auxDate);
		}
	},

	async mounted() {
		this.$nextTick(() => {
			setTimeout(() => {
				let defaults = {
					pagination: {
						el: ".swiper-pagination",
						type: "bullets"
					},
					slidesPerView: 3,
					watchOverflow: true,
					freeMode: true,
					freeModeSticky: {
						enable: true,
						momentumRatio: 0.25,
						momentumVelocityRatio: 0.25,
						minimumVelocity: 0.1
					},
					loop: true,
					loopAdditionalSlides: 5,
					direction: "vertical",
					slideToClickedSlide: true,
					centeredSlides: true
				};
				const hours = new Swiper(
					`.swiper-container.hours${this._uid}`,
					{
						...defaults,
						initialSlide: this.getHourPosition
					}
				);
				hours.on("slideChange", v => {
					this.handlerTime({ hour: this.hours[v.realIndex] });
				});

				const minutes = new Swiper(
					`.swiper-container.minutes${this._uid}`,
					{
						...defaults,
						initialSlide: this.getMinutePosition
					}
				);
				minutes.on("slideChange", v => {
					this.handlerTime({ minute: this.minutes[v.realIndex] });
				});

				const seconds = new Swiper(
					`.swiper-container.seconds${this._uid}`,
					{
						...defaults,
						enabled: false,
						initialSlide: 1
					}
				);
				seconds.on("slideChange", v => {
					this.handlerTime({ second: v.realIndex });
				});

				this.handlerTime({
					hour: this.hours[this.getHourPosition],
					minute: this.minutes[this.getMinutePosition]
				});
			}, 200);
		});
	}
};
</script>
<style lang="scss" scoped>
.picker {
	position: relative;
	width: 300px;
	overflow: hidden;
	margin: 1rem auto 0;
	padding: 0 30px;
	background-color: #fff;
	color: #252525;
}

.swiper-container {
	width: 80px;
	height: 210px;
	float: left;
}

.swiper-slide {
	text-align: center;
	font-size: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	opacity: 0.25;
	transition: opacity 0.3s ease;
	cursor: default;
	font-weight: bold;
	-webkit-tap-highlight-color: transparent;
}

.swiper-slide-prev,
.swiper-slide-next {
	cursor: pointer;
}

.swiper-slide-active {
	opacity: 1;
}

.vizor {
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	height: 70px;
	position: absolute;
	top: 50%;
	left: 1rem;
	right: 1rem;
	transform: translateY(-50%);
	font-size: 2rem;
	line-height: 62px;
}

.vizor:before,
.vizor:after {
	content: ":";
	display: inline-block;
	line-height: inherit;
	height: 100%;
	position: absolute;
	top: 0;
	transform: translateX(-50%);
}

.vizor:before {
	left: 95px;
}

.vizor:after {
	left: 175px;
}

.arrows .swiper-container:after,
.arrows .swiper-container:before {
	content: "";
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 4px;
	border-color: transparent;
	opacity: 0.5;
}

.arrows .swiper-container:before {
	top: 0.5rem;
	border-top-width: 0;
	border-bottom-color: currentColor;
}

.arrows .swiper-container:after {
	bottom: 0.5rem;
	border-bottom-width: 0;
	border-top-color: currentColor;
}
</style>
