<template>
	<v-menu
		ref="menu"
		v-model="menu"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		absolute
	>
		<template v-slot:activator="{ on, attrs }">
			<div>
				<div class="primary--text subtitle-2 ml-2">{{ title }}</div>
				<v-text-field
					class="rounded-lg"
					ref="input"
					:messages="showMessage"
					dense
					outlined
					readonly
					:rules="rules"
					:placeholder="showPlaceholder"
					:value="model"
					@focus="$emit('focus')"
					@update:error="error = $event"
					@input="$emit('input', $event)"
					v-bind="!menuDisabled ? attrs : null"
					v-on="!menuDisabled ? on : null"
				>
					<template v-slot:prepend-inner>
						<v-icon class="material-icons-outlined"
							>schedule</v-icon
						>
					</template>
					<template v-slot:append>
						<v-icon
							:color="icon.color"
							class="material-icons-outlined"
							>{{ icon.icon }}</v-icon
						>
					</template>
				</v-text-field>
			</div>
		</template>
		<v-card flat class="">
			<v-toolbar height="30px" flat dense>
				<v-toolbar-title
					class="flex text-center body-2 font-weight-bold mb-n5"
					>{{ $t("global.timePicker") }}</v-toolbar-title
				>
			</v-toolbar>
			<TimePicker v-model="auxDate" :key="picker" />
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text small color="primary" @click="cancel()">{{
					$t("global.btnCancel")
				}}</v-btn>
				<v-btn text small color="primary" @click="confirm()">OK</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import dayjs from "dayjs";
import TimePicker from "./TimePickerNormal";
import {
	FORMAT_TIME,
	FORMAT_TIME_A,
	FORMAT_TIME_EMPTY
} from "@/helpers/constants";
export default {
	components: {
		TimePicker
	},
	props: {
		value: String,
		title: String,
		messages: String,
		disabled: Boolean,
		placeholder: String,
		menuDisabled: Boolean,
		placeholderRules: {
			type: Boolean,
			default: true
		},
		rules: {
			type: Array,
			default: function() {
				return [v => !!v || this.$t("global.required")];
			}
		}
	},
	data: vm => ({
		menu: false,
		error: false,
		auxDate: vm.value,
		picker: 1
	}),
	watch: {
		menu(v) {
			if (v) this.updateTimePicker();
		},
		value(v) {
			this.auxDate = v;
		}
	},
	methods: {
		confirm() {
			this.$emit("input", this.auxDate);
			this.cancel();
		},
		updateTimePicker() {
			if (this.auxDateFormated != this.valueFormated) {
				this.auxDate = this.value;
				this.picker++;
			}
		},
		cancel() {
			this.updateTimePicker();
			this.menu = false;
		}
	},
  mounted() {
    if(!this.$refs.input?.valid) {
      this.error = true
    }
  },
	computed: {
		auxDateFormated: vm => dayjs(vm.auxDate).format(FORMAT_TIME),
		valueFormated: vm => dayjs(vm.value).format(FORMAT_TIME),
		showMessage: vm => (!vm.error ? vm.messages : undefined),
		showPlaceholder: vm => {
			if (vm.placeholderRules)
				if (vm.error) {
					if (vm.rules.map(x => x())[0] === true) return "";
					return vm.rules.map(x => x())[0];
				}
			return vm.placeholder;
		},
		model: {
			get() {
				if (!this.value) return FORMAT_TIME_EMPTY;
				let aux = dayjs(this.value);
				return aux.isValid()
					? aux.format(FORMAT_TIME_A)
					: FORMAT_TIME_EMPTY;
			}
		},
		icon: vm => {
      return !vm.error
				? vm.value
					? { icon: "check_circle", color: "green" }
					: { icon: "check_circle", color: "grey" }
				: { icon: "error_outline", color: "red" }
    }

	}
};
</script>
